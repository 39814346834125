<div class="d-flex align-items-center justify-content-center">
    <button (click)="viewStackingVideo()" class="btn btn-primary mx-2 my-1">View Stacking</button>

    <div class="mx-2 rectangle" *ngFor="let item of containerData?.container?.products" >
        <div class="mx-2">
            {{item.id}}
        </div>
        <div style="height:10px" [ngStyle]="{'background-color':item.color}">
        </div>
    </div>
</div>

<div class="visualization-container"></div>