import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class OrderFormsService {
  constructor(public _fb: FormBuilder) {}

  createReceivedPurchaseOrderForm(): FormGroup {
    return this._fb.group({
      totalSku: [],
      isUpdated: [false],
      isReceiving: [false],
      audit: [null],
      paymentEnabled: [false],
      id: [null],
      buyingType: ['SKU'],
      qoh: [null],
      contactId: [null],
      deliveryCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: null,
      }),
      debugInformation: [null],
      status: ['DRAFT'],
      purchaseOrderId: [null],
      saleOrderId: [null],
      saleOrderStatus: [null],
      saleOrderFromId: [null],
      saleOrderToId: [null],
      saleOrderTransactionId: [null],
      transactionId: [null],
      isQuickCheckout: [null],
      media_urls: [],
      media_url_ids: [],
      requestFrom: this._fb.group({
        id: [null],
        debugInformation: null,
        businessLines: [null],
        relationAccounts: [null],
        branchDetails: null,
        code: null,
        externalId: null,
        businessLogo: null,
        companyStamp: null,
        digitalStamp: null,
        isSystemAccount: null,
        type: null,
        roleName: null,
        fromInvite: false,
        inviteId: null,
        name: [null],
        firstName: [null],
        lastName: [null],
        language: [null],
        currency: [null],
        currency2nd: [null],
        gst: [null],
        primaryContact: [null],
        hibernateLazyInitializer: [null],
      }),
      requestTo: this._fb.group({
        id: [null],
        debugInformation: null,
        businessLines: [null],
        relationAccounts: [null],
        branchDetails: null,
        code: null,
        externalId: null,
        businessLogo: null,
        companyStamp: null,
        digitalStamp: null,
        isSystemAccount: null,
        type: null,
        roleName: null,
        fromInvite: false,
        inviteId: null,
        name: [null],
        firstName: [null],
        lastName: [null],
        language: [null],
        currency: [null],
        currency2nd: [null],
        gst: [null],
        primaryContact: [null],
        hibernateLazyInitializer: [null],
      }),
      requiredByDate: [null],
      returnDate: [null],
      importLocalType: ['LOCAL'],
      referenceContainerId: [null],
      isCreateNewContainer: [false],
      exporterBusinessId: [null],
      deliveryPickup: [null],
      reminderCount: [null],
      odometer: this._fb.group({
        value: [null],
        odometerType: [null],
      }),
      incoTermId: [null],
      arrivalPortId: [null],
      departurePortId: [null],
      containerTypeId: [null],
      dadyInPoints: [null],
      containerTypeDescription: [null],
      noteId: [null],
      noteTemplate: this._fb.group({
        id: [null],
      }),
      noteDescription: [null],
      isRead: [false],
      fulfilmentType: [],
      cost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      totalCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      metricCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      weight: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      volume: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      buddyAccounts: [null],
      productPackages: this._fb.array([]),
      editTillDate: [null],
      expectedByDate: [null],
      date: [this.getTodayDate()],
      shipper: [null],
      quantity: [1],
      discount: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      salesTax: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      rfQuotationId: [null],
      contactName: [null],
      contactPhone: [null],
      palletTypeInformation: [null],
      paymentStatus: [null],
      paymentTermId: [null],
      referenceOrder: [null],
      loadingTypeId: [null],
    });
  }

  productPackageForm(): FormGroup {
    return this._fb.group({
      id: [null],
      audit: [null],
      isReceiving: [false],
      deliveryDays: [null],
      isCustomized: [null],
      isNoGenericPurchase: [null],
      skuQuantities: [null],
      unitQuantities: [null],
      quantity: [1],
      qoh: [null],
      loadingType: [null],
      isCostInputByUser: [],
      cost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      totalCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      weight: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      totalWeight: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      volume: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      totalVolume: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      metricCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      productDetails: this._fb.group({
        id: [null],
        audit: [null],
        debugInformation: [null],
        preferredCustomerId: [null],
        cost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        weight: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        density: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        volume: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        surfaceArea: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        metricCost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuCost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuWeight: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuVolume: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuMetricCost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        palletTypeInformation: [null],
        productMetaId: [null],
        skuPackageId: [null],
        productCode: [null],
        description: [null],
        exportDescription: [null],
        upcCode: [null],
        eanCode: [null],
        rating: [null],
        isCustomizable: [null],
        isSaleable: [null],
        isFavourite: [null],
        isRawMaterial: [null],
        isSupplies: [null],
        isPackagingMaterial: [null],
        isProductOfProcess: [null],
        isSelfProduct: [null],
        numberOfItems: [null],
        numberOfProducts: [null],
        numberInPallet: [null],
        skuPackageContent: [null],
        qoh: [null],
        productTypeId: [null],
        productSubTypeId: [null],
        productTemplateId: [null],
        productTemplateName: [null],
        productTemplateCode: [null],
        productTypeDescription: [null],
        productSubTypeDescription: [null],
        productTemplateDescription: [null],
        skuPackageType: [null],
        odometer: this._fb.group({
          value: [null],
          odometerType: [null],
        }),
        skuOdometer: this._fb.group({
          value: [null],
          odometerType: [null],
        }),
        productImageFileName: [null],
        considerCost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        considerSkuCost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuPrice: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        status: ['DRAFT'],
        auditBusinessAccountName: [null],
        skuFirstMinimumQuantity: [null],
        skuFirstMaximumQuantity: [null],
        skuFirstSkuType: [null],
        skuSecondMinimumQuantity: [null],
        skuSecondMaximumQuantity: [null],
        skuSecondSkuType: [null],
        skuThirdMinimumQuantity: [null],
        skuThirdMaximumQuantity: [null],
        skuThirdSkuType: [null],
        palletSkuType: [null],
        palletMqo: [null],
        truckSkuType: [null],
        truckMqo: [null],
        containerSkuType: [null],
        containerMqo: [null],
        skuFirstTier: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuSecondTier: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuThirdTier: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        palletTier: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        truckTier: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        containerTier: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        isQuickCheckoutEligible: [null],
        isPalletAvailable: [null],
      }),
      packageCustomAttributeValues: this._fb.array([]),
      sortOrder: [null],
      skuCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),

      productId: [null],
      packageId: [null],
      totalSKUs: [null],
    });
  }

  productPackageRfqForm(): FormGroup {
    return this._fb.group({
      audit: [null],
      productId: [null],
      packageId: [null],
      qoh: [null],
      id: [null],
      quantity: [1],
      isCostInputByUser: [],
      previousRFQId: [null],
      previousRFQPurchaseDate: [null],
      previousRFQQuantity: [null],
      previousRFQUnitCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      previousRFQVendorCode: [null],
      skuCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      cost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      totalCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      weight: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      totalWeight: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      volume: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      totalVolume: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      metricCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
      productDetails: this._fb.group({
        id: [null],
        debugInformation: [null],
        preferredCustomerId: [null],
        cost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        weight: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        density: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        volume: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        surfaceArea: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        metricCost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuCost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuWeight: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuVolume: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        skuMetricCost: this._fb.group({
          attributeValue: [null],
          userConversionUom: [null],
        }),
        palletTypeInformation: [null],
        productMetaId: [null],
        skuPackageId: [null],
        productCode: [null],
        description: [null],
        exportDescription: [null],
        upcCode: [null],
        eanCode: [null],
        rating: [null],
        isCustomizable: [null],
        isSaleable: [null],
        isFavourite: [null],
        isRawMaterial: [null],
        loadingTypeId: [null],
        isSupplies: [null],
        isPackagingMaterial: [null],
        isProductOfProcess: [null],
        numberOfItems: [null],
        numberOfProducts: [null],
        numberInPallet: [null],
        skuPackageContent: [null],
        qoh: [null],
        productTypeId: [null],
        productSubTypeId: [null],
        productTemplateId: [null],
        productTemplateName: [null],
        productTemplateCode: [null],
        productTypeDescription: [null],
        productSubTypeDescription: [null],
        productTemplateDescription: [null],
        skuPackageType: [null],
        odometer: [null],
        skuOdometer: [null],
        productImageFileName: [null],
        audit: [null],
      }),
      packageCustomAttributeValues: [null],
      sortOrder: [null],
      totalSKUs: [null],
    });
  }

  createRfqForm(): FormGroup {
    return this._fb.group({
      isReceiving: [false],
      isUpdated: [false],
      audit: [],
      totalSku: [],
      cost: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      isQuickCheckout: [null],
      paymentTermId: [null],
      id: [null],
      transactionId: [null],
      buyingType: ['SKU'],
      loadingType: [null],
      media_urls: [],
      media_url_ids: [],
      requestFrom: this._fb.group({
        id: [null],
        debugInformation: null,
        businessLines: [null],
        relationAccounts: [null],
        branchDetails: null,
        code: null,
        externalId: null,
        businessLogo: null,
        companyStamp: null,
        digitalStamp: null,
        isSystemAccount: null,
        type: null,
        roleName: null,
        fromInvite: false,
        inviteId: null,
        name: [null],
        firstName: [null],
        lastName: [null],
        language: [null],
        currency: [null],
        currency2nd: [null],
        gst: [null],
        primaryContact: [null],
        hibernateLazyInitializer: [null],
      }),
      requestTo: this._fb.group({
        id: [null],
        debugInformation: null,
        businessLines: [null],
        relationAccounts: [null],
        branchDetails: null,
        code: null,
        externalId: null,
        businessLogo: null,
        companyStamp: null,
        digitalStamp: null,
        isSystemAccount: null,
        type: null,
        roleName: null,
        fromInvite: false,
        inviteId: null,
        name: [null],
        firstName: [null],
        lastName: [null],
        language: [null],
        currency: [null],
        currency2nd: [null],
        gst: [null],
        primaryContact: [null],
        hibernateLazyInitializer: [null],
      }),
      date: [this.getTodayDate()],
      expectedByDate: [null],
      returnDate: [null],
      dadyInPoints: [null],
      palletTypeInformation: [null],
      requiredByDate: [null],
      status: ['DRAFT'],
      // rfqId: [null],
      importLocalType: ['LOCAL'],
      deliveryPickup: [null],
      productAttributes: [null],
      reminderCount: [null],
      incoTermId: [null],
      loadingTypeId: [null],
      quotationId: [null],
      arrivalPortId: [null],
      departurePortId: [null],
      containerTypeId: [null],
      noteId: [null],
      noteTemplate: this._fb.group({
        id: [null],
      }),
      noteDescription: [null],
      isRead: [null],
      productPackages: this._fb.array([]),
      messages: this._fb.array([]),
      weight: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      volume: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      odometer: this._fb.group({
        value: [null],
        odometerType: [null],
      }),
      referenceOrder: this._fb.group({
        rfQuotationId: [null],
        quotationId: [null],
        purchaseOrderId: [null],
        saleOrderId: [null],
      }),
      paymentTerm: [null],
      editTillDate: [null],
      shipper: [null],
      quantity: [1],
      contact: [null],
      discount: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      salesTax: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      deliveryCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
    });
  }

  createQuotationForm(): FormGroup {
    return this._fb.group({
      media_urls: [],
      totalSku: [],
      isQuickCheckout: [null],
      media_url_ids: [],
      expiryDate: [this.get15DaysAfterTodayDate()],
      isReceiving: [false],
      is_updated: [false],
      audit: [],
      paymentTermId: [null],
      id: [null],
      transactionId: [null],
      buyingType: ['SKU'],
      loadingType: [null],
      requestFrom: this._fb.group({
        id: [null],
        name: [null],
        firstName: [null],
        lastName: [null],
      }),
      requestTo: this._fb.group({
        id: [null],
        name: [null],
        firstName: [null],
        lastName: [null],
      }),
      date: [this.getTodayDate()],
      expectedByDate: [null],
      returnDate: [null],
      dadyInPoints: [null],
      palletTypeInformation: [null],
      requiredByDate: [null],
      status: ['DRAFT'],
      // rfqId: [null],
      importLocalType: ['LOCAL'],
      deliveryPickup: [null],
      productAttributes: [null],
      reminderCount: [null],
      incoTermId: [null],
      loadingTypeId: [null],
      quotationId: [null],
      arrivalPortId: [null],
      departurePortId: [null],
      containerTypeId: [null],
      noteId: [null],
      noteTemplate: this._fb.group({
        id: [null],
      }),
      noteDescription: [null],
      isRead: [null],
      productPackages: this._fb.array([]),
      messages: this._fb.array([]),
      weight: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      volume: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      odometer: this._fb.group({
        value: [null],
        odometerType: [null],
      }),
      referenceOrder: this._fb.group({
        rfQuotationId: [null],
        quotationId: [null],
        purchaseOrderId: [null],
        saleOrderId: [null],
      }),
      paymentTerm: [null],
      editTillDate: [null],
      shipper: [null],
      quantity: [1],
      contact: [null],
      discount: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      salesTax: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      cost: this._fb.group({
        attributeValue: [null],
        userConversionuom: [null],
      }),
      deliveryCost: this._fb.group({
        attributeValue: [null],
        userConversionUom: [null],
      }),
    });
  }

  createMessageForm() {
    return this._fb.group({
      audit: [null],
      id: [null],
      debugInformation: [null],
      text: [null],
      isRead: [null],
      createdDate: [null],
      businessAccountId: [null],
    });
  }

  getTodayDate() {
    const prevseventhDate = new Date();
    const year = prevseventhDate.getFullYear();
    const month = ('0' + (prevseventhDate.getMonth() + 1)).slice(-2);
    const day = ('0' + prevseventhDate.getDate()).slice(-2);
    const dateString = `${year}-${month}-${day}`;
    return dateString;
  }

  get15DaysAfterTodayDate() {
    const current = new Date();
    const expiryDate = new Date(current.getTime() + 15 * 24 * 60 * 60 * 1000);
    const year = expiryDate.getFullYear();
    const month = ('0' + (expiryDate.getMonth() + 1)).slice(-2);
    const day = ('0' + expiryDate.getDate()).slice(-2);
    const dateString = `${year}-${month}-${day}`;
    return dateString;
  }
}
