<div class="datatable-container">
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
    <ng-container *ngFor="let col of headers; let i = index" [matColumnDef]="col.prop">
      <ng-container *ngIf="col.prop === 'select'">
        <mat-header-cell [ngStyle]="{
            'min-width': getMinWidth(col),
            'max-width': getMaxWidth(col)
          }" *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>

        <mat-cell [ngStyle]="{
            'min-width': getMinWidth(col),
            'max-width': getMaxWidth(col)
          }" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? onSelectRow(row) : null"
            [checked]="selection.isSelected(row.id)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="col.prop !== 'select'">
        <mat-header-cell [ngStyle]="{
            'min-width': getMinWidth(col),
            'max-width': getMaxWidth(col)
          }" *matHeaderCellDef [disabled]="!col?.sortable" mat-sort-header [ngClass]="{
            'custom-header': col?.dataType && col?.dataType == 'number'
          }" class="w-100">
          <div class="d-flex flex-column w-100">
            <div [ngClass]="{
                'center-cell-header': col?.dataType && col?.dataType == 'number'
              }">
              {{ headers[i]?.name }}
            </div>
            <div [ngClass]="{
                'center-cell-header': col?.dataType && col?.dataType == 'number'
              }">
              {{this.tableData ? this.tableData[0]?.[headers[i]?.prop]?.userConversionUom : ''}}
            </div>
          </div>
        </mat-header-cell>
        <mat-cell [ngStyle]="{
            'min-width': getMinWidth(col),
            'max-width': getMaxWidth(col)
          }" *matCellDef="let row; let rowIndex = index">
          <div class="d-flex align-items-center mx-2" *ngIf="col.name == 'PRODUCT DETAILS'">
            <div *ngIf="row?.isQuickCheckoutEligible" class="primary-text d-flex align-items-center"
              (mouseenter)="$event.stopPropagation()">
              <img width="24px" [matTooltip]="'Available for QC'"
                src="../../../../assets/img/sidebar-icons/Fast Cart-1.png" />&nbsp;
            </div>
            <div *ngIf="row?.isMyProduct" class="primary-text d-flex align-items-center"
              (mouseenter)="$event.stopPropagation()">
              <img width="24px" [matTooltip]="'My Product'" src="../../../../assets/img/icons/myproduct.svg" />&nbsp;
            </div>
            <div *ngIf="row?.isCustomizable" class="primary-text d-flex align-items-center"
              (mouseenter)="$event.stopPropagation()">
              <img width="20px" [matTooltip]="'Customizable'"
                src="../../../../assets/img/icons/Exclusive Product-Blue.png" />&nbsp;
            </div>
          </div>

          <div class="width-perc-95 d-flex align-items-center justify-content-between" [ngClass]="{
              'center-cell': col?.dataType == 'number' && col?.dataType,
              'plr-1':
                col?.dataType == 'number' && col?.dataType && col.sortable
            }">
            <ng-template #tooltipinfo>
              <div class="row">
                <div class="col-12" *ngIf="col.prop == 'productCode'">
                  <img *ngIf="row?.productImageFileNames?.length > 0" [src]="imgUrl + row?.productImageFileNames[0]"
                    width="300" height="300" />
                  <br />
                  {{ row.productCode }}
                </div>
                <div class="col-12" *ngIf="col.prop == 'templateCode'">
                  {{ row.description }}
                </div>
              </div>
            </ng-template>
            <ng-template #customtooltip>
              <div class="row customtooltip">
                <div class="col-md-12">
                  <label class="tooltip-title">
                    <b> Owner: </b>
                  </label>
                  <!-- <span>{{row.audit.businessAccountId == businessId && userShow ? 'Dadyin' : 'Self'}}</span> -->
                  <span>{{ row?.auditBusinessAccountName }}</span>
                </div>
                <div class="col-12" *ngIf="row?.createdBy == 'S'">
                  <label> <b>Created Name: </b> </label><span>{{ row.audit.createdByName }}</span>
                  <br />
                  <label> <b> Created Date: </b></label><span>{{
                    row.audit.createdDate | date : "MM-dd-yyyy"
                    }}</span>
                  <br />
                  <label> <b>Last Modified Name: </b></label><span>{{ row.audit.lastModifiedByName }}</span><br />
                  <label> <b> Last Modified Date: </b></label><span>{{
                    row.audit.lastModifiedDate | date : "MM-dd-yyyy"
                    }}</span>
                </div>
              </div>
            </ng-template>
            <div *ngIf="col.prop == 'productCode'" class="audit me-1 text-center" [ngClass]="{
                'self-product': row?.createdBy == 'S',
                'third-product': row?.createdBy == 'T',
                'master-product': row?.createdBy == 'M'
              }" [attr.data-label]="col.name" [ngbTooltip]="customtooltip" placement="top" container="body">
              <span>{{ row?.createdBy }}</span>
            </div>
            <ng-container *ngIf="!col.isLink">
              <ng-container *ngIf="col.type === 'serial_no'">
                <div [attr.data-label]="col.name" class="text-ellipsis">
                  <span>{{ rowIndex + 1 }} </span>
                </div>
              </ng-container>

              <ng-template #icontooltip>
                <div class="profile-actions">
                  <ng-container *ngFor="
                      let action of actions.slice(3, actions?.length);
                      let i = index
                    ">
                    <div *ngIf="check(action, row)"
                      class="d-flex align-items-center p-1 primary-text justify-content-end w-100">
                      <mat-icon *ngIf="!action?.type" [matTooltip]="action.label" class="edit-icon primary-text">{{
                        action.icon }}</mat-icon>
                      <img width="20px" [matTooltip]="action.label" *ngIf="action?.type == 'image'" [src]="action?.icon"
                        alt="" />
                      <span class="title-text mb-2" (click)="
                          onActionArrayClick(action, rowIndex, row);
                          $event.stopPropagation()
                        ">{{ action.label }}</span>
                    </div>
                  </ng-container>
                </div>
              </ng-template>

              <ng-container *ngIf="col.type === 'menu'">
                <div class="d-flex align-items-center justify-content-end w-100">
                  <dadyin-button [matTooltip]="
                      row.paymentEnabled
                        ? 'Pay now'
                        : 'Payment is not enabled by vendor'
                    " [isDisabled]="!row.paymentEnabled" *ngIf="row?.showPayNow" (click)="$event.stopPropagation()"
                    type="image" (clicked)="navigateToPayment(row)" [typeval]="'assets/img/icons/Cash.png'"
                    [theme]="'borderless-primary'">
                  </dadyin-button>
                  <ng-container *ngFor="let action of actions?.slice(0, 3)">
                    <div *ngIf="check(action, row)" class="actionicons">
                      <mat-icon *ngIf="!action?.type" (click)="
                          onActionArrayClick(action, rowIndex, row);
                          $event.stopPropagation()
                        " [matTooltip]="action.label" class="edit-icon primary-text">{{ action.icon }}</mat-icon>

                      <img width="24px" [matTooltip]="action.label" (click)="
                          onActionArrayClick(action, rowIndex, row);
                          $event.stopPropagation()
                        " *ngIf="action?.type == 'image'" [src]="action?.icon" alt="" />
                    </div>
                  </ng-container>

                  <mat-icon *ngIf="actions.length > 3" class="edit-icon" triggers="manual" #t="ngbTooltip"
                    (click)="t.open(); $event.stopPropagation()" [ngbTooltip]="icontooltip"
                    placement="bottom-end">more_vert</mat-icon>

                  <!-- related to customer/vendor listing for invite concept  -->
                </div>
              </ng-container>

              <ng-container *ngIf="!col.type && !col.isLink">
                <div container="body" placement="right" [ngbTooltip]="
                    col.prop == 'productCode' || col.prop == 'templateCode'
                      ? tooltipinfo
                      : null
                  " class="d-flex align-items-center">

                  <span *ngIf="col.prop == 'transactionId'">
                    <mat-icon matTooltip="Container Order" *ngIf="row?.importLocalType == 'CONTAINER'"
                      class="primary-text">
                      anchor
                    </mat-icon>
                    <mat-icon matTooltip="Local Order" *ngIf="row?.importLocalType == 'LOCAL'" class="primary-text">
                      location_on
                    </mat-icon>
                  </span>

                  <span *ngIf="col.prop == 'relationAccountName'">
                    <mat-icon *ngIf="row?.isImportExport" class="primary-text">
                      anchor
                    </mat-icon>
                    <mat-icon *ngIf="!row?.isImportExport" class="primary-text">
                      location_on
                    </mat-icon>
                  </span>

                  <div [attr.data-label]="col.name" class="text-ellipsis" [ngClass]="{
                      'text-end': col?.dataType && col?.dataType == 'number'
                    }">
                    <span *ngIf="
                        col?.dataType == 'number' &&
                        col?.decimal != false &&
                        col?.isCurrency
                      ">
                      {{ row[col.prop] | numberFormatter : 3 }}
                    </span>
                    <span *ngIf="
                        col?.dataType == 'number' &&
                        col?.decimal != false &&
                        col?.isPercent
                      ">
                      {{ row[col.prop] | numberFormatter : 3 }}
                    </span>
                    <span *ngIf="col?.dataType == 'number' && col?.decimal == false">
                      {{ row[col.prop] }}
                    </span>
                    <span *ngIf="col?.dataType != 'number'">
                      {{ row[col.prop] }}
                    </span>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="col.type == 'date'">
                <div [attr.data-label]="col.name" class="text-ellipsis">
                  <span> {{ row[col.prop] | date : "dMMMyy" }} </span>
                </div>
              </ng-container>

              <ng-container *ngIf="col.type == 'uom'">
                <div [attr.data-label]="col.name" class="text-ellipsis">
                  <!-- <span> {{row[col.prop]?.attributeValue  | numberFormatter:3}} </span> -->
                  <span *ngIf="!col?.hideNumberFormatter">
                    {{ row[col.prop]?.attributeValue | numberFormatter : 2 }}
                  </span>
                  <span *ngIf="col?.hideNumberFormatter">
                    {{ row[col.prop]?.attributeValue | numberFormatter : 0 }}
                  </span>
                </div>
              </ng-container>

              <ng-container *ngIf="col.type == 'audit'">
                <div class="audit text-center" [ngClass]="{
                    'self-product': row?.createdBy == 'S',
                    'third-product': row?.createdBy == 'T',
                    'master-product': row?.createdBy == 'M'
                  }" [attr.data-label]="col.name" [ngbTooltip]="customtooltip" placement="right" container="body">
                  <span>{{ row?.createdBy }}</span>
                </div>
              </ng-container>

              <ng-container *ngIf="col.type === 'list_count'">
                <div [attr.data-label]="col.name">
                  {{ row[col.prop] ? row[col.prop].length : "-" }}
                </div>
              </ng-container>
              <ng-container *ngIf="col.type === 'status'">
                <div class="status text-ellipsis" [attr.data-label]="col.name">
                  <span>{{ row[col.prop] ? row[col.prop] : "-" }}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="col.type == 'addressHover'">
                <div [attr.data-label]="col.name" [ngbTooltip]="addressTooltip" placement="right" class="text-ellipse"
                  container="body">
                  <span>{{ row[col.prop] }}</span>
                </div>

                <ng-template #addressTooltip>
                  <div class="row customtooltip">
                    <div class="col-md-12">
                      <span>{{ row?.addressLine }}</span>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="col.type == 'addressLine'">
                <div class="text-center" [attr.data-label]="col.name" [ngbTooltip]="addressTooltip" placement="right"
                  container="body">
                  <span>{{ row?.addressLine }}</span>
                </div>

                <ng-template #addressTooltip>
                  <div class="row customtooltip">
                    <div class="col-md-12">
                      <span>{{ row?.addressLine }}</span>
                    </div>
                  </div>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="col.type === 'viewnote'">
                <section class="d-flex w-100 justify-content-between align-items-center" (click)="
                    onActionArrayClick({ label: 'Note' }, rowIndex, row);
                    $event.stopPropagation()
                  ">
                  <span [matTooltip]="row[col.prop]" style="
                      white-space: pre;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    ">
                    {{ row[col.prop] }}
                  </span>
                  <i [matBadge]="row?.totalNotes" [matBadgeHidden]="!row?.totalNotes" matTooltip="View all notes"
                    class="material-icon mx-1" (click)="
                      onActionArrayClick({ label: 'Note' }, rowIndex, row);
                      $event.stopPropagation()
                    ">description</i>
                </section>
              </ng-container>

              <ng-container *ngIf="col.type === 'reminderDetails'">
                <section class="d-flex w-100 justify-content-between" (click)="
                    onActionArrayClick({ label: 'Reminder' }, rowIndex, row);
                    $event.stopPropagation()
                  ">
                  <span [matTooltip]="row[col.prop]" class="text-ellipse">
                    <b class="text-primary" *ngIf="row['reminderTime']">
                      {{ row["reminderTime"] | date : "dMMMyy h:mm a" : "UTC" }}
                      |
                    </b>
                    {{ row[col.prop] }}
                  </span>
                  <i [matBadge]="row?.totalReminders" [matBadgeHidden]="!row?.totalReminders" matTooltip="Add Reminder"
                    class="material-icon mx-1">notification_add</i>
                </section>
              </ng-container>
              <ng-container *ngIf="col.type === 'updateStatus'">
                <div [attr.data-label]="col.name">
                  <select class="b-light w-100" [(ngModel)]="row[col.prop]"
                    (ngModelChange)="onEdit($event, row, col.prop)" (click)="$event.stopPropagation()">
                    <option *ngFor="let item of apiService.allRelationStatuses" [value]="item.id">
                      {{ item.description }}
                    </option>
                  </select>
                </div>
              </ng-container>
              <ng-container *ngIf="col.type === 'updateSalesrep'">
                <div style="z-index: 0" (click)="$event.stopPropagation()" class="w-100" [attr.data-label]="col.name">
                  <ng-select appendTo="body" [(ngModel)]="row[col.prop]" (ngModelChange)="onEdit($event, row, col.prop)"
                    [multiple]="true" [searchable]="true" [clearable]="false" class="custom custom-multiple-table"
                    [placeholder]="placeholder" [items]="businessAccountService.employeesList" labelForId="yesno"
                    bindValue="id" bindLabel="firstName">
                    <ng-template ng-multi-label-tmp let-items="items">
                      <ng-container *ngIf="items.length == 1">
                        <div *ngFor="let item of items | slice : 0 : 1" class="ng-value">
                          <span container="body" placement="right" [ngbTooltip]="item.email" class="ng-value-label">
                            {{ item.firstName }}
                          </span>
                        </div>
                      </ng-container>

                      <div *ngIf="items.length > 1" class="ng-value">
                        <span class="ng-value-label">{{ items.length }}</span>
                      </div>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                      <span class="ng-value-label" container="body" placement="right" [ngbTooltip]="item.email">
                        {{ item.firstName }}</span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-clear="clear">
                      <span container="body" placement="right" [ngbTooltip]="item.email" class="ng-value-label">
                        {{ item.firstName }}
                      </span>
                      <span style="float: right" class="ng-value-icon right close-icon-option"
                        aria-hidden="true">×</span>
                    </ng-template>
                  </ng-select>
                </div>
              </ng-container>
              <ng-container *ngIf="col.type === 'inputUpdate'">
                <input class="b-light w-100" type="text" [(ngModel)]="row[col.prop]"
                  (change)="onEdit($event, row, col.prop)" (click)="$event.stopPropagation()" />
              </ng-container>
            </ng-container>
            <!-- Making table cell as link to open in new tab -->
            <ng-container *ngIf="col.isLink">
              <div container="body" placement="right" [ngbTooltip]="
                  col.prop == 'productCode' || col.prop == 'templateCode'
                    ? tooltipinfo
                    : null
                " (click)="$event.stopPropagation()">
                <a (click)="gotToLink(col.link + row[col.idKey])" [attr.data-label]="col.name" class="text-ellipsis"
                  id="link-in-table-cell">
                  {{ row[col.prop] }}
                </a>
              </div>
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row style="cursor: pointer" (click)="editRecord(rowIndex, row)"
      *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator #paginator [length]="pageConfig?.totalElements" [showTotalPages]="pageConfig?.totalPages"
    *ngIf="tableData?.length && pageConfig" [pageSizeOptions]="pageConfig.sizeOption ? pageConfig.sizeOption : []"
    (page)="onPageChange($event)" [pageSize]="pageConfig?.itemPerPage" [showFirstLastButtons]="true">
  </mat-paginator>
</div>
<div *ngIf="!tableData || tableData.length === 0" class="no-data-found">
  <span class="mx-p-b-10"> No records found </span>
</div>