<div class="process-table">
  <!-- Material Cost -->
  <table>
    <thead>
      <tr>
        <th colspan="4">Product Code/Description</th>
        <th class="width-100 weight">
          <div class="group">
            <span>WT/SKU ({{
              productPackages?.controls[0]
              ?.get("weight")
              .get("userConversionUom").value
              }})
            </span>
          </div>
        </th>
        <th class="width-100 volume">
          <div class="group">
            <span>VOL/SKU ({{
              productPackages?.controls[0]
              ?.get("volume")
              .get("userConversionUom").value
              }})
            </span>
          </div>
        </th>
        <th class="width-100">
          <span>QOH</span>
        </th>
        <th class="width-100">
          <span>SKU PRICE ({{
            productPackages?.controls[0]
            ?.get("cost")
            .get("userConversionUom").value
            }})
          </span>
        </th>
        <th class="width-125">
          <span>QTY SKUS </span>
        </th>
        <th class="width-125">
          <span>Sub Total ({{
            productPackages?.controls[0]
            ?.get("totalCost")
            .get("userConversionUom").value
            }})
          </span>
        </th>
        <th>
          <span>ACTION</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="productPackages?.controls?.length == 0">
        <tr>
          
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="actions">
            <dadyin-button [isDisabled]="!isEditable" (clicked)="addProductPackage()"
              [theme]="'borderless-primary'" type="icon" [typeval]="'add_circle_outline'">
            </dadyin-button>
          </td>
        </tr>
      </ng-container>
      <ng-container [formGroup]="productPackage" *ngFor="
          let productPackage of productPackages?.controls;
          let i = index
        ">
        <tr>
        
          <td colspan="4" formGroupName="productDetails">
            <ng-container *ngIf="!productPackage.get('productId').value">
              <input (input)="getProductsList($event)" placeholder="Search for Product Code/Description" [matAutocomplete]="description"
                 formControlName="description" />
              <mat-autocomplete (optionSelected)="onChange($event.option.value, productPackage)" autoActiveFirstOption #description="matAutocomplete">
                <mat-option [value]="item" *ngFor="let item of productsList">
                  <span style="padding: 3px" class="mx-1" [ngClass]="{
                      'self-product':
                        businessAccountService.getOwner(item?.audit) ==
                        'S',
                      'third-product':
                        businessAccountService.getOwner(item?.audit) ==
                        'T',
                      'master-product':
                        businessAccountService.getOwner(item?.audit) ==
                        'M'
                    }">
                    ({{ businessAccountService.getOwner(item.audit) }})
                  </span>
                  {{ item.productCode }} ({{ item.description }})
                </mat-option>
              </mat-autocomplete>
            </ng-container>

            <ng-container *ngIf="productPackage.get('productId').value">
              <div class="bg-disable">
                <span style="padding: 3px" class="mx-1" [ngClass]="{
                    'self-product':
                      businessAccountService.getOwner(
                        productPackage
                          .get('productDetails')
                          .get('audit').value
                      ) == 'S',
                    'third-product':
                      businessAccountService.getOwner(
                        productPackage
                          .get('productDetails')
                          .get('audit').value
                      ) == 'T',
                    'master-product':
                      businessAccountService.getOwner(
                        productPackage
                          .get('productDetails')
                          .get('audit').value
                      ) == 'M'
                  }">
                  ({{
                  businessAccountService.getOwner(
                  productPackage.get("productDetails").get("audit")
                  .value
                  )
                  }})
                </span>
                (
                {{
                productPackage
                .get("productDetails")
                .get("productCode").value
                }}
                )
                {{
                productPackage
                .get("productDetails")
                .get("description").value
                }}
              </div>
            </ng-container>
          </td>
          <td class="weight">
            <div class="bg-disable">
              {{
              productPackage.get("weight").get("attributeValue").value
              | numberFormatter : 3
              }}
            </div>
          </td>
          <td class="volume">
            <div class="bg-disable">
              {{
              productPackage.get("volume").get("attributeValue").value
              | numberFormatter : 3
              }}
            </div>
          </td>
          <td>
            <div class="bg-disable">
              {{ productPackage.get("qoh").value }}
            </div>
          </td>
          <td formGroupName="cost">
            <div *ngIf="
                !isEditable 
              " class="bg-disable" [matTooltip]="getMetricCost(productPackage)">
              {{
              productPackage.get("cost").get("attributeValue").value
              | numberFormatter : 3
              }}
            </div>
            <input (change)="changeCostByUser(productPackage)" *ngIf="
                isEditable
              " type="number" formControlName="attributeValue" />
          </td>
          <td class="text-center">
            <div [matTooltip]="isQuantityLessThanContainerMqo(i)"
              class="bg-white b-all my-1 d-flex align-items-center">
              <dadyin-button [theme]="'borderless-primary'" type="image"
                [typeval]="'assets/img/icons/Minus.svg'"
                (clicked)="updateProductQuantityInOrder(i, -1)"></dadyin-button>
              <input [readonly]="
                  !productPackage.get('productId').value 
                " [matTooltip]="productPackage.value?.loadingType" name="quantity"
                (change)="changeQuantityInOrder(i)" type="number" min="1" class="text-center bg-white"
                formControlName="quantity" />
              <dadyin-button [theme]="'borderless-primary'" type="image"
                [typeval]="'assets/img/icons/added.svg'" (clicked)="updateProductQuantityInOrder(i, 1)">
              </dadyin-button>
            </div>
          </td>
          <td>
            <div>
              {{
              productPackage.get("totalCost").get("attributeValue")
              .value | numberFormatter : 3
              }}
            </div>
          </td>
          <td class="actions">
            <dadyin-button *ngIf="
                status.value != 'CONFIRMED' &&
                status.value != 'PLACED' &&
                status.value != 'REJECTED'
              " [isDisabled]="!isEditable" (clicked)="deleteProductPackage(i)" [theme]="'borderless-danger'"
              type="icon" [typeval]="'delete_outline'">
            </dadyin-button>
            <ng-container *ngIf="
                productPackages.controls?.length == i + 1 &&
                status.value != 'CONFIRMED' &&
                status.value != 'PLACED' &&
                status.value != 'REJECTED'
              ">
              <dadyin-button [isDisabled]="!isEditable" (clicked)="addProductPackage()"
                [theme]="'borderless-primary'" type="icon" [typeval]="'add_circle_outline'">
              </dadyin-button>
            </ng-container>
          </td>
        </tr>
      </ng-container>

      <ng-container>
        <tr class="bg-light">
          <td colspan="4">
            <div class="title-text primary-text">TOTAL</div>
          </td>
          <td>
            <div class="title-text">
              {{
              orderForm.get("weight").get("attributeValue")
              .value | numberFormatter : 3
              }}
            </div>
          </td>
          <td>
            <div class="title-text">
              {{
              orderForm.get("volume").get("attributeValue")
              .value | numberFormatter : 3
              }}
            </div>
          </td>
          <!-- <td>
            <div class="title-text"></div>
          </td> -->
          <td></td>
          <td></td>
          <td>
            <div class="title-text text-center">
              {{ orderForm.get("totalSku").value }}
            </div>
          </td>
          <td>
            <div class="title-text">
              {{
              orderForm.get("cost").get("attributeValue")
              .value | numberFormatter : 3
              }}
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>