import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BusinessAccountService } from 'src/app/project/postlogin/business-account/business-account.service';
import { OrderFormsService } from 'src/app/project/postlogin/order-management/service/order-forms.service';

@Component({
  selector: 'app-order-transaction-packages',
  templateUrl: './order-transaction-packages.component.html',
  styleUrls: ['./order-transaction-packages.component.scss'],
})
export class OrderTransactionPackagesComponent implements OnInit {
  @Input() productPackages:any
  @Input() isEditable:any
  @Input() orderForm:any
  @Input()  productsList
  @Output() calculate = new EventEmitter();
  @Output() getProducts= new EventEmitter();

  constructor(
    public orderFormService:OrderFormsService,
    public businessAccountService:BusinessAccountService
  ) {}

  ngOnInit(): void {
  }

  get buyingType() {
    return this.orderForm.get('buyingType')
  }
  get status() {
    return this.orderForm.get('status')
  }



  getMetricCost(productPackage) {
    return productPackage.get('metricCost').value?.attributeValue + ' ' + productPackage.get('metricCost').value?.userConversionUom
  }

  changeCostByUser(productPackageForm) {
    productPackageForm.get('isCostInputByUser').patchValue(true)
    this.calculateValues()
  }


  changeQuantityInOrder(i) {
    if(!this.productPackages.controls[i].get('productId').value ) {
     return
    }
    if(!this.isEditable) {
      return
    }
    const quantityControl = this.productPackages.controls[i].get('quantity')
    if (quantityControl.value < 1) {
      quantityControl.patchValue(1)
      return
    }
    quantityControl.patchValue(quantityControl.value) 
    this.calculateValues()
  }


  isQuantityLessThanContainerMqo(i) {
    const productControl = this.productPackages.controls[i]
    const quantityControl = this.productPackages.controls[i].get('quantity')
    if (['CONTAINER_40_FT', 'CONTAINER_20_FT', 'CONTAINER_40_FT_HQ']?.includes(this.buyingType?.value) && (quantityControl.value < productControl.value.productDetails?.containerMqo)) {
      return 'Quantity is less than container MQO ' + productControl.value.productDetails?.containerMqo
    }
    else {
      return ''
    }
  }

 

  updateProductQuantityInOrder(i, quantity) {
    if(!this.productPackages.controls[i].get('productId').value ) {
      return
     }
    if(!this.isEditable) {
      return
    }
    const quantityControl = this.productPackages.controls[i].get('quantity')

    if (quantityControl.value < 1) {
      quantityControl.patchValue(1)
      return
    }
    quantityControl.patchValue(quantityControl.value + quantity)
    this.calculateValues()
  }





  addProductPackage() {
    const productPackageForm = this.orderFormService.productPackageForm()
    productPackageForm.get('quantity').patchValue(1)
    this.productPackages.push(productPackageForm)
  }

  deleteProductPackage(i: any) {
    this.productPackages.removeAt(i)
    this.calculateValues()
  }


  calculateValues() {
    this.calculate.emit(null)
  }

  onChange(item, productPackage: FormGroup) {
    productPackage.patchValue(item, {
      emitEvent: false,
      onlySelf: true,
    })
    productPackage.get('productDetails').patchValue(item, {
      emitEvent: false,
      onlySelf: true,
    })
    if (['CONTAINER_40_FT', 'CONTAINER_20_FT', 'CONTAINER_40_FT_HQ']?.includes(this.buyingType?.value)) {
      productPackage.get('quantity').patchValue(item?.containerMqo);
    }
    else {
      productPackage.get('quantity').patchValue(1);
    }
    productPackage.get('id').patchValue(null);
    productPackage.get('productId').patchValue(item.id);
    productPackage.get('packageId').patchValue(item.skuPackageId);

    this.calculateValues()
  }


  getProductsList(event) {
    console.log(event)
    this.getProducts.emit(event)
  }


}
